<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="3">
              <CInput
                :placeholder="$t('search_a') + $t('order_sn')"
                v-model="searchData.sn"
                v-on:keyup.enter="getOrders()"
              />
            </CCol>
            <CCol col="12" sm="3">
              <CSelect
                :placeholder="$t('search_a') + $t('order_status')"
                :options="statusOptions"
                :value.sync="searchData.status"
              />
            </CCol>
            <CCol col="12" sm="4">
              <date-picker
                v-model="searchData.date_range"
                :shortcuts="false"
                :editable="false"
                :not-after="$moment()"
                range-separator="~"
                range
                width="100%"
                value-type="date"
                format="YYYY-MM-DD"
                :input-class="['form-control', 'bg-white']"
              />
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getOrders()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <font slot="sn" slot-scope="props">
              {{ '#' + props.row.sn }}
            </font>
            <font slot="shipping_method" slot-scope="props">
              {{ props.row.shipping ? props.row.shipping.shipping_method.name : '' }}
            </font>
            <font slot="payment_method" slot-scope="props">
              {{ props.row.payment ? props.row.payment.payment_method.name : '' }}
            </font>
            <font slot="payment_price" slot-scope="props">
              {{ props.row.payment ? props.row.payment.currency.code + props.row.payment.currency.symbol + props.row.payment.total_price : '' }}
            </font>
            <CBadge slot="status" slot-scope="props" :color="$_.find(statusOptions, option => { return option.value === props.row.status }).color">
              {{ $_.find(statusOptions, option => { return option.value === props.row.status }).label }}
            </CBadge>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="dark" v-bind="{ variant: 'outline' }" @click="() => detailsOrderModal = { data: props.row, modal: true }">{{ $t('details') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editOrderModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <DetailsOrderModal :data="detailsOrderModal.data" :show="detailsOrderModal.modal" @showUpdate="show => detailsOrderModal.modal = show" />

      <EditOrderModal :data="editOrderModal.data" :show="editOrderModal.modal" @showUpdate="show => editOrderModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import DetailsOrderModal from './modal/DetailsOrderModal'
import EditOrderModal from './modal/EditOrderModal'

export default {
  name: 'orders',
  components: {
    DetailsOrderModal,
    EditOrderModal,
  },
  data () {
    return {
      columns: [ 'sn', 'shipping_method', 'payment_method', 'payment_price', 'status', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          sn: this.$t('order_sn'),
          shipping_method: this.$t('shipping_method'),
          payment_method: this.$t('payment_method'),
          payment_price: this.$t('payment_price'),
          status: this.$t('order_status'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'sn', 'shipping_method', 'payment_method', 'payment_price', 'created_at', 'status' ],
        filterable: [ 'sn', 'shipping_method', 'payment_method', 'payment_price' ]
      },
      statusOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('order_status') + '...' },
        { value: 0, label: this.$t('incomplete'), color: 'warning' },
        { value: 1, label: this.$t('pending_payment'), color: 'warning' },
        { value: 2, label: this.$t('being_prepared'), color: 'info' },
        { value: 3, label: this.$t('logistics_in_progress'), color: 'info' },
        { value: 4, label: this.$t('logistics_complete'), color: 'info' },
        { value: 8, label: this.$t('completed'), color: 'success' },
        { value: 9, label: this.$t('cancelled'), color: 'danger' },
        { value: 10, label: this.$t('expired'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: { sn: '', status: null, date_range: [this.$moment({hour: 0}).subtract(7, 'd'), this.$moment({hour: 0})] },
      detailsOrderModal: { data: {}, modal: false },
      editOrderModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    getOrders () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      const searchData = this.$_.clone(this.searchData)
      searchData.date_range[1] = this.$moment(searchData.date_range[1]).endOf('d')
      this.$store.dispatch('getOrders', searchData).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>

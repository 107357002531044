<template>
  <CModal
    :title="$t('edit_order')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="12">
        <h2>{{ $t('order') }}</h2>
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('order_sn')"
          :placeholder="$t('order_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('order_price')"
          :placeholder="$t('order_price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.order_price"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CSelect
          :label="$t('order_status')"
          :options="statusOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.status"
          :isValid="valid_formData_status"
        />
      </CCol>
    </CRow>
    <CRow v-if="formData.shipping">
      <CCol col="12" sm="12">
        <hr>
        <h2>{{ $t('shipping') }}</h2>
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('shipping_sn')"
          :placeholder="$t('shipping_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('third_party_sn')"
          :placeholder="$t('third_party_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.third_party_sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('shipping_method')"
          :placeholder="$t('shipping_method')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.shipping_method.name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('shipping_fee')"
          :placeholder="$t('shipping_fee')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.shipping_fee"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('recipient_name')"
          :placeholder="$t('recipient_name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.recipient_name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('recipient_phone')"
          :placeholder="$t('recipient_phone')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.recipient_phone"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('recipient_email')"
          :placeholder="$t('recipient_email')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.recipient_email"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type !== 'CVS'">
        <CInput
          :label="$t('recipient_zipcode')"
          :placeholder="$t('recipient_zipcode')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.recipient_zipcode"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type !== 'CVS'">
        <CInput
          :label="$t('recipient_address')"
          :placeholder="$t('recipient_address')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.recipient_address"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type !== 'CVS'">
        <CInput
          :label="$t('booking_note_sn')"
          :placeholder="$t('booking_note_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.booking_note_sn"
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type === 'CVS'">
        <CInput
          :label="$t('store_sn')"
          :placeholder="$t('store_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.store_sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type === 'CVS'">
        <CInput
          :label="$t('store_name')"
          :placeholder="$t('store_name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.store_name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type === 'CVS'">
        <CInput
          :label="$t('store_phone')"
          :placeholder="$t('store_phone')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.store_phone"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type === 'CVS'">
        <CInput
          :label="$t('store_address')"
          :placeholder="$t('store_address')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.store_address"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type === 'CVS'">
        <CInput
          :label="$t('store_address')"
          :placeholder="$t('store_address')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.store_address"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type === 'CVS'">
        <CInput
          :label="$t('cvs_sn')"
          :placeholder="$t('cvs_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.cvs_sn"
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.shipping.shipping_method.type === 'CVS'">
        <CInput
          :label="$t('cvs_validation_code')"
          :placeholder="$t('cvs_validation_code')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.shipping.cvs_validation_code"
        />
      </CCol>
    </CRow>
    <CRow v-if="formData.payment">
      <CCol col="12" sm="12">
        <hr>
        <h2>{{ $t('payment') }}</h2>
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('payment_sn')"
          :placeholder="$t('payment_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.payment.sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('third_party_sn')"
          :placeholder="$t('third_party_sn')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.payment.third_party_sn"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('payment_method')"
          :placeholder="$t('payment_method')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.payment.payment_method.name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :label="$t('payment_price')"
          :placeholder="$t('payment_price')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.payment.payment_price"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.payment.payment_method.type === 'ATM'">
        <CInput
          :label="$t('atm_bank_code')"
          :placeholder="$t('atm_bank_code')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.payment.atm_bank_code"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.payment.payment_method.type === 'ATM'">
        <CInput
          :label="$t('atm_bank_account')"
          :placeholder="$t('atm_bank_account')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.payment.atm_bank_account"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="6" v-if="formData.payment.payment_method.type === 'ATM'">
        <CInput
          :label="$t('atm_expired')"
          :placeholder="$t('atm_expired')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.payment.atm_expired"
          plaintext
        />
      </CCol>
    </CRow>
    <CRow v-if="formData.order_details && formData.order_details.length > 0">
      <CCol col="12" sm="12">
        <hr>
        <h2>{{ $t('details') }}</h2>
      </CCol>
      <CCol col="12" sm="12">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">{{ $t('item') }}</th>
              <th scope="col">{{ $t('price_per_item') }}</th>
              <th scope="col">{{ $t('qty') }}</th>
              <th scope="col">{{ $t('subtotal_price') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="e in formData.order_details" :key="e.id">
              <td>
                <strong>{{ e.product_name }}</strong><br>
                <span class="text-muted">{{ e.product_options }}</span>
              </td>
              <td>{{ formData.currency.code + formData.currency.symbol + e.price_per_item }}</td>
              <td>{{ e.qty }}</td>
              <td>{{ formData.currency.code + formData.currency.symbol + e.sub_total_price }}</td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>
    <CRow v-if="formData.order_coupons && formData.order_coupons.length > 0">
      <CCol col="12" sm="12">
        <hr>
        <h2>{{ $t('coupons') }}</h2>
      </CCol>
      <CCol col="12" sm="12">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">{{ $t('coupon') }}</th>
              <th scope="col">{{ $t('discount') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="e in formData.order_coupons" :key="e.id">
              <td>
                <strong>{{ e.coupon_name }}</strong><br>
              </td>
              <td>{{ formData.currency.code + formData.currency.symbol + e.discount_price }}</td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
export default {
  name: 'EditOrderModal',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      statusOptions: [
        // { value: 0, label: this.$t('incomplete'), color: 'dark' },
        { value: 1, label: this.$t('pending_payment'), color: 'warning' },
        { value: 2, label: this.$t('being_prepared'), color: 'info' },
        { value: 3, label: this.$t('logistics_in_progress'), color: 'info' },
        { value: 4, label: this.$t('logistics_complete'), color: 'info' },
        { value: 8, label: this.$t('completed'), color: 'success' },
        { value: 9, label: this.$t('cancelled'), color: 'danger' },
        { value: 10, label: this.$t('expired'), color: 'secondary' }
      ],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showOrder()
      }
    }
  },
  computed: {
    valid_formData_status () {
      return /^[0-9]+$/.test(this.formData.status) ? true : false
    },
    valid_submit () {
      return this.valid_formData_status
    }
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    showOrder () {
      const loader = this.$loading.show()
      this.$store.dispatch('showOrder', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        this.formData.order_price = this.formData.currency.code + this.formData.currency.symbol + this.formData.total_price
        if (this.formData.payment) {
          this.formData.payment.payment_price = this.formData.payment.currency.code + this.formData.payment.currency.symbol + this.formData.payment.total_price
        }
        if (this.formData.shipping) {
          this.formData.shipping.shipping_fee = this.formData.shipping.currency.code + this.formData.shipping.currency.symbol + this.formData.shipping.shipping_fee
          this.formData.shipping.recipient_phone = this.formData.shipping.recipient_phone ? this.formData.shipping.recipient_calling_code + this.formData.shipping.recipient_phone : null
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateOrder', { id: this.formData.id, status: this.formData.status, booking_note_sn: this.formData.shipping ? this.formData.shipping.booking_note_sn : null, cvs_sn: this.formData.shipping ? this.formData.shipping.cvs_sn : null, cvs_validation_code: this.formData.shipping ? this.formData.shipping.cvs_validation_code : null }).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
  },
  beforeDestroy: function () {
  },
}
</script>
